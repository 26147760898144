<template>
    <el-container>
        <el-main>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>供片系统管理方</el-breadcrumb-item>
                <el-breadcrumb-item>发行版管理</el-breadcrumb-item>
                <el-breadcrumb-item>影片管理</el-breadcrumb-item>
            </el-breadcrumb>
            <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
                <el-button v-if="editOptions.open && !selector" type="primary" @click="editFormVisible = true">添加影片</el-button>
                <el-button type="primary" @click="importFormVisible=true">导入影片</el-button>
                <Download :url="exportXlsUrl"  icon="export" :params="this.searchParams" replaceFileName="导出影片列表.xls">导出查询结果</Download>
            </SearchForm>
            <template v-if="tableOptions.open">
                <el-table :data="tableOptions.tableData" stripe border size="small"
                           header-cell-class-name="table_header"
                          cell-class-name="table_cell" style="width: 100%">
                    <el-table-column type="index" label="序号" width="50"/>
                    <template v-if="tableOptions.items">
                        <template v-for="item in tableOptions.items">
                            <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                                <template slot-scope="scope">
                                    <template v-if="item.props">
                                        <template v-if="item.props[scope.row[item.name]]">
                                            <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                                                <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                                                    {{item.props[scope.row[item.name]]}}
                                                </el-tag>
                                            </template>
                                            <template v-else>
                                                {{item.props[scope.row[item.name]]}}
                                            </template>
                                        </template>
                                        <template v-else-if="scope.row[item.name]">
                                            <el-tag type="danger" disable-transitions>
                                                {{scope.row[item.name]}}
                                            </el-tag>
                                        </template>
                                        <template v-else>
                                            {{scope.row[item.name]}}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <el-tag type="warning" disable-transitions>
                                            {{scope.row[item.name]}}
                                        </el-tag>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                                <template slot-scope="scope">
                                    <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                                            disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column v-else :prop="item.name" :label="item.label"/>
                        </template>
                    </template>
                    <el-table-column
                            label="海报">
                        <template slot-scope="scope">
                            <el-image style="width: 50px; height: 50px" :src="imageUrl(scope.row.id, 'phone')"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column min-width="200" label="操作">
                        <template slot-scope="scope">
                            <template v-if="!selector">
                                <el-button type="text" @click="openVersions(scope.row.id)">
                                    版本管理 <i class="el-icon-files"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="openPlatemakings(scope.row)">
                                    制版平台 <i class="el-icon-files"></i>
                                </el-button>
                                <el-button type="text" @click="openJyplat(scope.row)">
                                    发布到运营平台 <i class="el-icon-files"></i>
                                </el-button>
                                <template v-if="editOptions.open">
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="editHandle(scope.row.id)">
                                        修改 <i class="el-icon-edit"></i>
                                    </el-button>
                                </template>
                                <template v-if="detailOptions.open">
                                    <el-divider direction="vertical"></el-divider>
                                    <el-button type="text" @click="openDetail(scope.row.id)">
                                        详细 <i class="el-icon-document"></i>
                                    </el-button>
                                </template>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="imageHandle(scope.row.id)">
                                    海报 <i class="el-icon-picture-outline"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button v-if="scope.row.status === 0" type="text" @click="enableHandle(scope.row.id)">
                                    启用 <i class="el-icon-sell"></i>
                                </el-button>
                                <el-button v-if="scope.row.status === 1" type="text" @click="disableHandle(scope.row.id)">
                                    停用 <i class="el-icon-sold-out"></i>
                                </el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" @click="synchronizeStatusHandle(scope.row)">
                                  最近同步时间 <i class="el-icon-refresh"></i>
                                </el-button>
                            </template>
                            <template v-else>
                                <el-button type="text" @click="selectHandle(scope.row)">
                                    选择 <i class="el-icon-check"></i>
                                </el-button>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        layout="total, sizes, prev, pager, next, jumper"
                        :page-sizes="[10, 20, 50, 100]"
                        :current-page="tableOptions.page"
                        :page-size="tableOptions.limit"
                        :total="tableOptions.total">
                </el-pagination>
            </template>
            <!--popup search-->
            <el-dialog title="版本管理" :visible.sync="versionsVisible" center width="70%" append-to-body :before-close="closeMenu">
                <Menu1_Version :params="searchOptions.searchParams"></Menu1_Version>
            </el-dialog>
            <el-dialog :title="menuTitle" :visible.sync="platemakingsVisible" center width="70%" append-to-body :before-close="closeMenu">
                <Menu1_FilmPlatemaking :params="searchOptions.searchParams"></Menu1_FilmPlatemaking>
            </el-dialog>
            <el-dialog :title="menuCplTitle" :visible.sync="jyplatVisible" center width="70%" append-to-body :before-close="closeMenu">
                <Menu1_jyplat :params="searchOptions.searchParams"></Menu1_jyplat>
            </el-dialog>
            <el-dialog :title="editOptions.id?'修改数据':'添加数据'" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
                <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm"></EditForm>
            </el-dialog>
            <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
                <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
            </el-dialog>
            <el-dialog title="导入设备清单" :visible.sync="importFormVisible" append-to-body destroy-on-close center>
                <SingleUpload :url="importUrl" :maxsize="10" @done="importTemplateSuccess">
                    <Download :url="downloadUrl" replace-file-name="影片上传登记模板.xls"></Download>
                </SingleUpload>
            </el-dialog>
            <el-dialog :title="fileTitle" :visible.sync="importImageVisible" append-to-body destroy-on-close center>
                <div style="margin:0 auto;display: flex;flex-direction: column;align-items: center;gap: 15px;">
                    <h2>当前海报(点击放大)</h2>
                    <!--                <el-image :src="imageData"></el-image>-->
                    <div class="demo-image__preview">
                        <el-image
                                style="width: 100px; height: 100px"
                                :src="url"
                                :preview-src-list="srcList">
                        </el-image>
                    </div>
                    <SingleUpload :url="postImageUrl+cplId" :maxsize="10" :exts="['jpg','jpeg']" @done="importImageSuccess" style="padding-right:140px">
                    </SingleUpload>
                </div>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
    import SearchForm from "../common/OptionalSearchForm";
    import Menu1_Version from "./Menu1_Version";
    import Menu1_FilmPlatemaking from "./Menu1_FilmPlatemaking";
    import Menu1_jyplat from "./Menu1_jyplat";
    import EditForm from "../common/EditForm";
    import DetailPanel from "../common/DetailPanel";
    import SingleUpload from "../common/SingleUpload";
    import Download from "../common/Download";
    import {Search_Type} from "../../constants";
    const prefix = '/film';
    const getListUrl = `${prefix}/list`;
    const getOneUrl = (id) => `${prefix}/one/${id}`
    const getDetailUrl = (id) => `${prefix}/detail/${id}`
    const postUrl = `${prefix}/save`;
    const putUrl = (id) => `${prefix}/update/${id}`
    const enableUrl = (id)=>`${prefix}/enable/${id}`
    const disableUrl = (id)=>`${prefix}/disable/${id}`
    const postImageUrl = `${prefix}/save/image/`;
    const importUrl = `${prefix}/import`;
    const downloadUrl = `${prefix}/template`;
    const synlogUrl = (id) => `/synlog/one/${id}`;
    const exportXlsUrl = `${prefix}/exportxls`;
    export default {
        components: {
          Menu1_FilmPlatemaking,Menu1_jyplat,
          SearchForm,Menu1_Version,EditForm,DetailPanel,SingleUpload,Download},
        data(){
            return{
                Search_Type,
                tableOptions:{
                    tableData:[],
                    page: 1,
                    limit: 10,
                    total: 0,
                },
                searchOptions:{
                    searchForm: {},
                    searchParams:{},
                },
                editOptions: {
                    postUrl: postUrl,
                    getOneUrl: getOneUrl,
                    putUrl: putUrl,
                },
                detailOptions: {
                    getDetailUrl : getDetailUrl,
                },
                editFormVisible: false,
                versionsVisible: false,
                platemakingsVisible: false,
                jyplatVisible: false,
                detailPanelVisible: false,
                importImageVisible: false,
                importFormVisible: false,
                imageUrl: (id, type)=> `${this.g_getRequestBaseUrl()}/resource/image?id=${id}&type=${type}`,
                cplId: '',
                postImageUrl: postImageUrl,
                imageData: null,
                url: null,
                srcList: [],
                fileTitle: null,
                downloadUrl: downloadUrl,
                importUrl: importUrl,
                exportXlsUrl: exportXlsUrl,
                menuTitle: null,
                menuCplTitle: null,
            }
        },
        props:['params','selector'],
        computed: {
          searchParams:{
            get(){
              return {
                ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                ...this.searchOptions.searchForm
              }
            }
          }
        },
        created() {
            this.g_setupMetadataConfig(this, 'Menu1_Cpl')
            if(!this.params){
                if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
                    this.loadDynamicSelectData()
                }else{
                    this.getTableData();
                }
            }
        },
        methods: {
            loadDynamicSelectData(){
                let needLoad = true;
                //加载动态键值对
                this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
                    let dynamicUrl = item.dynamicUrl;
                    if(!dynamicUrl || item.props) return;
                    needLoad = false
                    this.$axios.get(dynamicUrl).then(res=>{
                        try {
                            let params = item.dynamicParams.split(',')
                            if(params.length !== 2) throw 'error parameter num'
                            item.p1 = params[0]
                            item.p2 = params[1]
                            item.props = res.data
                        }catch (e) {
                            console.log(e)
                        }
                        this.getTableData()
                    })
                })
                if(needLoad) this.getTableData()
            },
            getTableData(){
                this.$axios.get(getListUrl,{
                    params: {
                        ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
                        ...this.searchOptions.searchForm
                    }
                }).then(res=>{
                    this.tableOptions.tableData = res.data.records;
                    this.tableOptions.page = res.data.current;
                    this.tableOptions.limit = res.data.size;
                    this.tableOptions.total = res.data.total;
                })
            },
            handleSizeChange(limit){
                this.tableOptions.limit = limit;
                this.getTableData();
            },
            handleCurrentChange(page){
                this.tableOptions.page = page;
                this.getTableData();
            },
            handleSearch(){
                this.tableOptions.page = 1;
                this.getTableData();
            },
            enableHandle(id){
                this.$axios.put(enableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            disableHandle(id){
                this.$axios.put(disableUrl(id)).then(()=>{
                    this.$message({
                        showClose: true,
                        message: `恭喜你，操作成功`,
                        type: 'success',
                        duration: 1500,
                        onClose:() => {
                            this.getTableData();
                        }
                    });
                })
            },
            closeMenu(done){
                this.searchOptions.searchParams = []
                done();
            },
            openVersions(id){
                this.versionsVisible = true;
                this.searchOptions.searchParams = [{name:'agreementId',value:id}];
            },
            openPlatemakings(row){
                this.platemakingsVisible = true;
                this.menuTitle = `影片<${row.filmTitle}>的制版平台`
                this.searchOptions.searchParams = [{name:'agreementId',value:row.id}];
            },
            openJyplat(row){
                this.jyplatVisible = true;
                this.menuCplTitle = `影片<${row.filmTitle}>的发布的运营平台`
                this.searchOptions.searchParams = [{name:'syncKeyId',value:row.id}];
            },
            openDetail(id){
                this.detailOptions.id = id;
                //this.detailOptions.initData = row
                this.detailPanelVisible = true
            },
            editHandle(id){
                this.editOptions.id = id
                this.editFormVisible = true
            },
            closeEditForm(result){
                this.editOptions.id = null
                this.editFormVisible = false
                if(result){
                    this.getTableData()
                }
            },
            closeDetailPanel(){
                this.detailOptions.id = null
                this.detailPanelVisible = false
            },
            selectHandle(row){
                this.$emit('onselect', row)
            },
            importImageSuccess(){
                this.importImageVisible = false;
            },
            importTemplateSuccess(){
                this.importFormVisible = false;
                this.getTableData();
            },
            imageHandle(id){
                this.imageData = null;
                this.$axios.get(getOneUrl(id)).then(res=>{
                    this.cplId = id;
                    this.importImageVisible = true;
                    this.srcList = [this.imageUrl(id, 'origin'), this.imageUrl(id, 'phone')];
                    this.url = this.srcList[0];
                    this.fileTitle = res.data.filmTitle;
                })
            },
            synchronizeStatusHandle(row){
              this.$axios.get(synlogUrl(row.id)).then(res=>{
                this.$alert(res.data?res.data.createdate:'暂无', `[${row.filmTitle}]最近同步时间`, {
                  confirmButtonText: '好的'
                });
              })
            },
        }
    }
</script>

<style scoped>

</style>