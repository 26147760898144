<!--
    组件说明:
           propertys:    url(必须）下载地址
                         fileName(可选)缺省下载文件名
                         replaceFileName(可选)强制替换服务器返回的文件名
                         params 查询参数
-->
<template>
    <el-button type="primary" size="medium" @click="download">
        <slot>下载模版</slot>
        <i v-if="icon === 'download'" class="el-icon-download el-icon--right"></i>
        <i v-else-if="icon === 'export'" class="el-icon-tickets el-icon--right"></i>
    </el-button>
</template>

<script>
    export default {
        props:{
            url:{
                type: String,
                required: true
            },
            //缺省文件名
            fileName:{
                type: String,
                default: '文件模版.xls'
            },
            //替换fileName和response返回的fileName
            replaceFileName:{
                type: String,
                default: ''
            },
            params:{
              type: Object,
              default: ()=>{}
            },
            icon: {
              type: String,
              default: 'download'
            }
        },
        methods:{
            download(){
                this.$axios.get(this.url, {
                    responseType: 'blob', // important
                    timeout: 60000,
                    params: this.params
                }).then((response) => {
                    // console.log(response)
                    //返回的是一个错误
                    if(response.headers['content-type']==='application/json'){
                        let reader = new FileReader();
                        reader.onload = e => this.$message.error(JSON.parse(e.target.result).msg);
                        reader.readAsText(response.data);
                        return false;
                    }
                    //接收的是文件
                    let fileName = this.fileName;
                    if(response.headers["content-disposition"]){
                        const patt = new RegExp("filename=([^;]+\\.[^\\.;]+);*");
                        const result = patt.exec(response.headers["content-disposition"]);
                        if(result.length>1){
                            fileName = decodeURI(result[1]);
                        }
                    }
                    if(this.replaceFileName){
                        fileName = this.replaceFileName
                    }
                    const url = window.URL.createObjectURL(new Blob([response.data],
                        { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                });
            },
        }
    }
</script>

<style scoped>

</style>